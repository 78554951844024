@custom-media --screen-lt-sm (max-width: 480px);
/* < 480px */
@custom-media --screen-gt-sm (min-width: 481px);
/* > 480px */
@custom-media --screen-lt-md (max-width: 768px);
/* < 768 px */
@custom-media --screen-gt-md (min-width: 769px);
/* > 768 px */
@custom-media --screen-lt-lg (max-width: 959px);
/* < 950 px */
@custom-media --screen-gt-lg (min-width: 960px);
/* > 960 px */
@custom-media --screen-lt-xlg (max-width: 1120px);
/* < 1120px px */
@custom-media --screen-gt-xlg (min-width: 1120px);
/* > 1120px px */

html {
    scroll-padding-top: var(--space-96);

    @media (prefers-reduced-motion: no-preference) {
        scroll-behavior: smooth;
    }
}
