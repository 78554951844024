ul.sideScrollNav {
    margin-top: calc(-0.5 * var(--space-12));
    margin-bottom: calc(-0.5 * var(--space-12));

    li {
        padding-top: calc(0.5 * var(--space-12));
        padding-bottom: calc(0.5 * var(--space-12));

        > a {
            color: var(--color-grey-07);
            padding-inline-start: 10px;
            border-inline-start: 2px solid transparent;
            transition-property: color border-color;
            transition-duration: var(--animation-duration-150);
            transition-timing-function: var(--animation-ease-base);
            text-decoration: none;
        }

        & > a:hover {
            color: var(--color-grey-08);
            text-decoration: none;
        }

        &:not(.active) > a:hover {
            border-color: var(--color-grey-05);
        }

        &.active > a {
            color: var(--grey-08);
            border-color: var(--color-dst-primary);
        }
    }
}
